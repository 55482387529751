var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "500px",
        top: "17vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c("div", { staticStyle: { margin: "0 0 10px" } }, [
            _vm._v("查询时段: " + _vm._s(_vm.time)),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { "max-height": "400px" },
              attrs: { stripe: "", data: _vm.list },
            },
            _vm._l(_vm.finallyColumns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: "",
                  align: "center",
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }