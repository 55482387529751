<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="500px"
    top="17vh"
    :close-on-click-modal="false"
    destroy-on-close
    center
  >
    <div class="orderTest-container">
      <div style="margin: 0 0 10px">查询时段: {{ time }}</div>
      <el-table
        v-loading="loading"
        stripe
        :data="list"
        style="max-height: 400px"
      >
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          align="center"
        ></el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { missionCompleteDetail } from '@/api/performance'
  export default {
    name: 'Detail',
    components: {},
    data() {
      return {
        id: '',
        time: '',
        loading: false,
        showDialog: false,
        title: '',
        list: [],
        checkList: ['员工', '任务目标', '完成量', '完成率'],
        columns: [
          {
            order: 1,
            label: '员工',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '任务目标',
            prop: 'target_count',
            width: '',
          },
          {
            order: 3,
            label: '完成量',
            prop: 'result_count',
            width: '',
          },
          {
            order: 4,
            label: '完成率',
            prop: 'rate',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await missionCompleteDetail({
          id: this.id,
          month: this.time,
        })
        this.total = totalCount
        this.list = data
        this.loading = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
