<template>
  <!-- NAME[epic=绩效] 任务达成 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="" prop="type">
        <!-- <el-input v-model="form.type" placeholder="任务类型"></el-input> -->
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="任务类型"
        >
          <el-option
            v-for="(i, idx) in typeList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="name">
        <el-input v-model="form.name" placeholder="员工姓名、电话"></el-input>
      </el-form-item>
      <el-form-item label="" prop="status">
        <!-- <el-input v-model="form.status" placeholder="状态"></el-input> -->
        <el-select
          v-model="form.status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusList"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="month">
        <!-- <el-input v-model="form.month" placeholder=""></el-input> -->
        <el-date-picker
          v-model="form.month"
          type="month"
          placeholder="选择月"
          value-format="yyyy-M"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '状态'">
            <span v-if="row.status == 1">进行中</span>
            <span v-else-if="row.status == 2">停止</span>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleCheckRow($index, row)"
          >
            查看详细情况
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <detail ref="detail"></detail>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { missionCompleteList } from '@/api/performance'
  import Detail from './components/detail.vue'
  export default {
    name: 'TaskCompleted',
    components: { Detail },
    data() {
      return {
        // 1进行中2停止
        statusList: [
          {
            id: 1,
            name: '进行中',
          },
          {
            id: 2,
            name: '停止',
          },
        ],
        // 类型1拜访2建店3销售
        typeList: [
          {
            id: 1,
            name: '拜访任务',
          },
          {
            id: 2,
            name: '建店任务',
          },
          {
            id: 3,
            name: '销售任务',
          },
          {
            id: 4,
            name: '动销任务',
          },
        ],
        loading: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageNo: 1,
          pageSize: 10,
          type: '',
          name: '',
          status: '',
          month: '',
        },
        list: [],
        checkList: ['任务名称', '任务类型', '任务描述', '状态', '平均完成率'],
        columns: [
          {
            order: 1,
            label: '任务名称',
            prop: 'name',
            width: '',
          },
          {
            order: 2,
            label: '任务类型',
            prop: 'type_text',
            width: '',
          },
          {
            order: 3,
            label: '任务描述',
            prop: 'note',
            width: '',
          },
          {
            order: 4,
            label: '状态',
            prop: 'status',
            width: '',
          },
          {
            order: 5,
            label: '平均完成率',
            prop: 'complete',
            width: '',
          },
        ],
        rules: {
          month: [{ required: true, message: '请输入月份', trigger: 'blur' }],
        },
      }
    },
    computed: {
      nowMonth() {
        let t = new Date()
        let y = t.getFullYear()
        let m = t.getMonth() + 1
        return y + '-' + m
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.form.month = this.nowMonth
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.$refs.form.validate(async (val) => {
          if (val) {
            this.loading = true
            let { data, totalCount } = await missionCompleteList(this.form)
            this.total = totalCount
            this.list = data
            this.loading = false
          } else {
            this.list = []
          }
        })
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleCheckRow(index, row) {
        console.log(row)
        this.$refs.detail.id = row.id
        this.$refs.detail.time = this.form.month
        this.$refs.detail.title = row.name + ' ' + ' 完成情况'
        this.$refs.detail.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
